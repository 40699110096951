import React, { useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Sidebar } from 'semantic-ui-react'; // Import Sidebar
import { ThemeContext } from '../contexts/ThemeContext';
import ToggleButton from './ToggleButton';

const Nav = () => {
  const { themeColors } = useContext(ThemeContext); // Access theme and theme colors
  const [visible, setVisible] = useState(false); // Track sidebar visibility
  const toggleButtonRef = useRef(null); // Create a ref for the ToggleButton

  // Toggle the sidebar visibility
  const handleToggle = () => setVisible(!visible);

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        style={{ backgroundColor: themeColors.headerFooterBackground }}
      >
        <Menu.Item as={Link} to="/" onClick={handleToggle} style={{ color: themeColors.textColor, fontSize: '20px' }}>
          Home
        </Menu.Item>
        <Menu.Item as={Link} to="/about" onClick={handleToggle} style={{ color: themeColors.textColor, fontSize: '20px' }}>
          About
        </Menu.Item>
        <Menu.Item as={Link} to="/contact" onClick={handleToggle} style={{ color: themeColors.textColor, fontSize: '20px' }}>
          Contact
        </Menu.Item>
        <Menu.Item as={Link} to="/portfolio" onClick={handleToggle} style={{ color: themeColors.textColor, fontSize: '20px' }}>
          Portfolio
        </Menu.Item>

        <Menu.Item>
          <ToggleButton ref={toggleButtonRef} />
        </Menu.Item>
      </Sidebar>

      <Menu inverted style={{ backgroundColor: themeColors.headerFooterBackground }}>
        <Menu.Item onClick={handleToggle} style={{ color: themeColors.textColor }}>
          <Icon name="sidebar" size="large" />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Nav;



