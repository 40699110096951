import React, { forwardRef, useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ThemeContext } from '../contexts/ThemeContext'; // Import the ThemeContext

const ToggleButton = forwardRef((props, ref) => {
  const { theme, toggleTheme, themeColors } = useContext(ThemeContext); // Access theme and toggleTheme

  return (
    <Button
      ref={ref}
      onClick={toggleTheme}
      aria-label="Toggle Theme"
      style={{
        backgroundColor: themeColors.headerFooterBackground,
        color: themeColors.textColor,
        transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for background and text color
      }}
      icon
      {...props}
    >
      <Icon
        name={theme === 'light' ? 'sun' : 'moon'}
        style={{ color: themeColors.textColor }} // Ensure icon color matches theme text color
      />
    </Button>
  );
});

export default ToggleButton;


