import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import './Bubbles.css';

const Bubbles = () => {
  const { themeColors } = useContext(ThemeContext);
  const colors = themeColors.bubbleColors;

  useEffect(() => {
    const bubbles = document.querySelector('.bubbles');

    const createBubble = () => {
      const bubble = document.createElement('div');
      bubble.classList.add('bubble');
      bubble.style.left = `${Math.random() * 100}%`;
      bubble.style.bottom = `${Math.random() * 100}%`; // Randomize initial vertical position
      bubble.style.animationDuration = `${10 + Math.random() * 10}s`; // Randomize animation duration
      bubble.style.width = `${10 + Math.random() * 20}px`; 
      bubble.style.height = bubble.style.width;
      bubble.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
      bubbles.appendChild(bubble);

      setTimeout(() => {
        bubble.remove();
      }, 20000); // Match this with animation duration
    };

    const intervalId = setInterval(createBubble, 2000); // Interval for time between bubbles

    return () => clearInterval(intervalId);
  }, [colors]);

  return <div className="bubbles"></div>;
};

export default Bubbles;




