import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const ThemeContext = createContext();
const themes = {
  light: {
    primaryColor: '#007BFF', // Blue as primary color for a professional look
    headerFooterBackground: '#E0E0E0', // Soft gray
    textColor: '#212121', // Dark gray text for readability
    headerColor: '#212121', // Dark gray header for contrast
    h2Color: '#333333', // Darker gray for h2 headers 
    segmentBackground: '#FFFFFF', // Clean white background for segments
    cardBackground: '#F9F9F9', // Very light gray for card background (less harsh than pure white)
    cardTextColor: '#212121', // Dark gray for card text
    buttonBackground: '#007BFF', // Blue for a more professional look
    buttonTextColor: '#FFFFFF', // White text on button
    bubbleColors: ['rgba(255, 99, 71, 0.9)', 'rgba(255, 69, 0, 0.9)', 'rgba(255, 0, 0, 0.9)', 'rgba(178, 34, 34, 0.9)', 'rgba(139, 0, 0, 0.9)'], // Bubble colors for light theme
  },
  dark: {
    primaryColor: '#FF9800', // Bright orange for a vibrant look
    headerFooterBackground: '#333333', // Dark gray for header and footer
    textColor: '#E0E0E0', // Light gray text for readability
    headerColor: '#FFFFFF', // White header for contrast against the dark background
    h2Color: '#FF9800', // Bright orange for h2 headers in dark theme to stand out
    segmentBackground: '#444444', // Dark background for segments to separate from other content
    cardBackground: '#555555', // Slightly lighter dark gray for cards (still dark but with some contrast)
    cardTextColor: '#E0E0E0', // Light gray text for card content
    buttonBackground: '#FF9800', // Vibrant orange for a strong but not overpowering button color
    buttonTextColor: '#FFFFFF', // White text on button
    bubbleColors: ['rgba(255, 99, 71, 0.9)', 'rgba(255, 69, 0, 0.9)', 'rgba(255, 0, 0, 0.9)', 'rgba(178, 34, 34, 0.9)', 'rgba(139, 0, 0, 0.9)'], // Bubble colors for dark theme
  },
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light'); // Default theme
  const [themeColors, setThemeColors] = useState(themes.light); // Set initial theme colors

  useEffect(() => {
    // Check for saved theme in cookies on component mount
    const savedTheme = Cookies.get('theme');
    if (savedTheme) {
      setTheme(savedTheme);
      setThemeColors(themes[savedTheme]);
    }
  }, []);

  useEffect(() => {
    // Update theme colors and cookie whenever theme changes
    setThemeColors(themes[theme]);
    Cookies.set('theme', theme, { expires: 7 }); // Save theme in cookies for 7 days
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeColors }}>
      {children}
    </ThemeContext.Provider>
  );
};
