import React, { useContext } from 'react';
import { Container, Icon, Grid } from 'semantic-ui-react';
import SocialMediaIcons from './SocialMediaIcons';
import { ThemeContext } from '../contexts/ThemeContext'; 

const Footer = () => {
  const { themeColors } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: themeColors.headerFooterBackground, color: themeColors.textColor, padding: '2rem 0', width: '100%' }}>
      <Container fluid>
        <Grid stackable columns={3} textAlign="center">
          <Grid.Column>
            <p><strong><Icon name="address card" /> Contact Information:</strong></p>
            <p><strong><Icon name="building" /> Address:</strong> 270 Nantucket Pl, Newport News, VA 23606</p>
            <p><strong><Icon name="phone square" /> Phone:</strong> <a href="tel:+17577150427" style={{ color: themeColors.textColor }}>(757) 715-0427</a></p>
            <p><strong><Icon name="envelope open" /> Email:</strong> <a href="mailto:carterpaul1@yahoo.com" style={{ color: themeColors.textColor }}>carterpaul1@yahoo.com</a></p>
            <SocialMediaIcons />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
